<!-- 品牌馆、品类周、自定义类型组件 -->
<template>
  <div class="template-container">
    <ProductItem
      :key="`${analysisCode}_${productItem?.goods_id}`"
      v-tap="getTapAnalysis(productItem, productItemIndex)"
      v-expose="getExposeAnalysis(productItem, productItemIndex)"
      :config="productItemConfig"
      :item="productItem"
      :index="productItemIndex"
      :column="productsNum"
      :language="language"
      :show-view-more="showViewMore"
      :view-more-text="viewMoreText"
      :constant-data="constantData"
      :css-right="cssRight"
      :context="context"
      :crop-rate="cropRate"
      :lcp="isFirstPage && productItemIndex < 5"
      :comp-src="'multi-line-other-template'"
      :comp-img-design-width="192"
      :suggested-sale-type="suggestedSaleType"
      class="template-container__product-item"
      :price-font-size="priceFontSize"
      :class="{ 'product-simple__suggested_label': isShowLowestPriceLabelLang,[`other-product-item-${index}-${containerIndex}-${productItemIndex}`]: true, }"
      @clickItem="handleClickItem"
    />
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import { CompSize } from '../constants.js'
// 简易商卡
import ProductItem from 'public/src/pages/components/ccc/base/ProductItemSimpleCccBff'

export default {
  name: 'OtherTemplate',
  components: {
    ProductItem,
  },
  mixins: [mixins, useProductItemMixin],
  props: {
    ...commonProps,
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    productsList: {
      type: Array,
      default: () => []
    },
    productItem: {
      type: Object,
      default: () => {}
    },
    // 商品项索引
    productItemIndex: {
      type: Number,
      default: 0
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    showViewMore: {
      type: Boolean,
      default: false
    },
    viewMoreText: {
      type: String,
      default: ''
    },
    // 置顶商品abt
    homeAdpNum: {
      type: String,
      default: ''
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 商品数量
    productsNum: {
      type: Number,
      default: 0
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
    // 埋点analysisCode
    analysisCode: {
      type: String,
      default: ''
    },
  },
  data() {
    const { metaData: { componentSize, templateType, priceFontSize = {} } = {}, markMap = {} } = this.propData?.props || {}
    return {
      componentSize: Number(componentSize),
      templateType,
      markMap,
      priceFontSize
    }
  },
  computed: {
    adpProductsNum() {
      // 不带入展示商品,只带入点击商品
      if (this.homeAdpNum === 'no') {
        return 0
      } else if (parseInt(this.homeAdpNum)){
        return parseInt(this.homeAdpNum)
      } else {
        switch (this.componentSize) {
          case CompSize.full:
            return 10
          case CompSize.half:
            return 2
          default:
            return 1
        }
      }
    },
    cate() {
      return {
        markMap: this.markMap,
        hrefType: this.metaData.hrefType,
        hrefTarget: this.metaData.hrefTarget,
        item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
        component_area: this.componentSize, // 组件面积
        template: this.templateType, // 组件模板
        content_type: {
          homeBelttype: this.productItem?.homeBelt?.type || '',
          homeBelttext: this.productItem?.homeBelt?.text || '',
          homeBadgetype: this.productItem?.homeBadge?.type || '',
          homeBadgetext: this.productItem?.homeBadge?.text || '',
        },
      }
    },
    isShowLowestPriceLabelLang() {
      if (this.componentSize != 1 || this.homeDiscountNew !== 'compliance' || !this.productItem?.suggestedSalePriceInfo?.description) return false
      return true
    },
  },
  methods: {
    handleClickItem (params = {}) {
      const { goods_id = '' } = params
      // 将goods_id放到第一位
      const productList = this.productsList.slice(0, this.adpProductsNum)
      const goodsIdList = productList.map((i) => i.goods_id)
      const sortGoods = [goods_id, ...goodsIdList.filter(i => i !== goods_id)]
      this.goToProductPage({ goods_id: sortGoods })
    },
    goToProductPage ({ goods_id } = {}) {
      const params = {
        item: {
          ...this.metaData,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let goodsIds = goods_id || ''
      if(!goodsIds) {
        const goodsIdList = this.productList.map((i) => i.goods_id)
        goodsIds = goodsIdList.join(',')
      }
      if (this.metaData.hrefType !== 'webLink') {
        const adpStr = `adp=${goodsIds}`
        url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      }
      this.jumpLink({ url, hrefType: this.metaData.hrefType })
    },
    getTapAnalysis(item, i) {
      return this.getProductAnalysis('2-22-4', {
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.analysisCode}_code`
      })
    },
    getExposeAnalysis(item, i) {
      return this.getProductAnalysis('2-22-3', {
        type: 'expose',
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.analysisCode}_code`
      })
    },
  },
}
</script>

<style lang="less">
.template-container {
  width: 100%;
  overflow: hidden;
}
</style>
