<!-- 1/2、1/4 、整行组件标题区 -->
<template>
  <div
    v-tap="getTitleAnalysis({ id: '2-22-2' })"
    v-expose="getTitleAnalysis({ id: '2-22-1' })"
    class="multi-line__header"
    :style="[
      { color: titleColor },
      showRightIcon ? halfHeaderStye : null,
      isSingleContainer ? {...mainTitleStyle, marginRight: '.1067rem' } : null,
      isNotQuarterContainer ? { paddingLeft: '0.0533rem' } : null,
    ]"
    :class="{ 'multi-line__header_single': isSingleContainer }"
    @click="handleHeaderClick"
  >
    <!-- 主标题 -->
    <div
      class="multi-line__header_single-main"
      :style="{ maxWidth: showRightIcon ? 'calc(100% - 16px)' : '100%' }"
    >
      <div class="multi-line__header_main" :style="[isSingleContainer ? null : mainTitleStyle]">
        <p
          v-if="showTitleText"
          class="multi-line__header_title-main"
          :style="[titleCommonStyle ]"
        >
        {{ title }}
        </p>
        <BaseImg
          v-if="showTitleImage"
          fit="cover"
          :style="[titleImageSize]"
          :img-src="titleImage?.src"
          :placeholder="{
            width: getTitleImgWidth(),
            height: 16
          }"
          :ratio="titleImage?.ratio || 1"
          :position="cssRight ? '100% 50%' : '0% 50%'"
          class="not-fsp-element multi-line__header_title-main"
          :img-design-width="getTitleImgWidth() * 3"
          :first-screen="isFirstPage"
        />
        <!-- 整行、1/2 大促销logo -->
        <BaseImg
          v-if="isNotQuarterContainer && bigSaleLogo?.src"
          fit="cover"
          :style="[bigSaleLogoSize]"
          :img-src="bigSaleLogo?.src"
          :placeholder="{
            width: getTitlebigSaleWidth(),
            height: 16
          }"
          :ratio="bigSaleLogo.ratio"
          :position="cssRight ? '100% 50%' : '0% 50%'"
          class="not-fsp-element multi-line__header_promotion"
          :img-design-width="getTitlebigSaleWidth() * 3"
          :first-screen="isFirstPage"
        />
      </div>
      <!-- 1/2、1/4 副标题 -->
      <div
        v-if="subTitle && !isSingleContainer"
        class="multi-line__header_subTitle"
        :style="[titleCommonStyle, subTitleStyle, { color: subTitleColor }]"
      >
        {{ subTitle }}
      </div>
    </div>
    <!-- 整行副标题 -->
    <div
      v-if="isSingleContainer && showRightIcon && subTitle"
      class="multi-line__header_subTitle"
    >
      <p :style="[titleCommonStyle, subTitleStyle,  { color: subTitleColor, marginBottom: '0' }]">
         {{ subTitle }}
      </p>
    </div>
    <Icon
      v-if="showRightIcon"
      name="sui_icon_more_right_12px"
      :is-rotate="cssRight"
    />
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { TitleType, CompSize, CompTemplateType } from '../../constants'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { mapGetters } from 'vuex'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

export default {
  name: 'MultiLineHeader',
  components: {
    BaseImg,
    Icon
  },
  mixins: [mixin],
  props: {
    ...commonProps,
    cssRight: {
      type: Boolean,
      default: false
    },
    productsList: {
      type: Array,
      default: () => []
    },
    isFirstPage: {
      type: Boolean,
      default: false,
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    // 置顶商品abt
    homeAdpNum: {
      type: String,
      default: ''
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // 埋点analysisCode
    analysisCode: {
      type: String,
      default: ''
    }
  },
  data() {
    const {
      metaData: {
        templateType,
        componentSize,
        title,
        subTitle,
        titleType,
        titleImage,
        titleColor,
        bigSaleLogo,
        titleTextSize,
        subTitleTextSize,
        mallCodes
      } = {},
      markMap = {}
    } = this.propData?.props || {}
    const { height = 0, width = 0 } = titleImage || {}
    if (titleImage.src) {
      titleImage.src = titleImage.src.replace('.webp', '.png')
    }
    titleImage.ratio =  (width / height) || 1
    if (bigSaleLogo) {
      const { height: bigSaleHeight = 0, width: bigSaleWidth = 0 } = bigSaleLogo || {}
      bigSaleLogo.ratio =  (bigSaleWidth / bigSaleHeight) || 1
      if (bigSaleLogo.src) {
        bigSaleLogo.src = bigSaleLogo.src.replace('.webp', '.png')
      }
    }
    return {
      markMap,
      templateType,
      componentSize: Number(componentSize),
      title,
      subTitle,
      titleType,
      titleImage,
      titleColor,
      bigSaleLogo,
      titleTextSize,
      subTitleTextSize,
      mallCodes
    }
  },
  computed: {
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    tabIndex() {
      return this.topTabList?.findIndex(tab => tab.channelId === this.channelId) ?? ''
    },
    // 是否单行，且不显示副标题
    isSingleContainer() {
      return this.componentSize == CompSize.full
    },
    // 1/4和店铺不展示右侧箭头
    showRightIcon() {
      return (
        this.componentSize != CompSize.quarter &&
        this.templateType != CompTemplateType.shop
      )
    },
    isNotQuarterContainer() {
      return this.componentSize !== CompSize.quarter
    },
    showTitleImage() {
      return this.titleType === TitleType.image && this.titleImage?.src
    },
    showTitleText() {
      return this.titleType === TitleType.text && this.title
    },
    titleCommonStyle() {
      let fontSize = '0.3467rem'
      if (this.titleTextSize && !this.checkSizeUnit(this.titleTextSize)) {
        fontSize = (this.titleTextSize / 37.5) + 'rem'
      }
      return {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize,
      }
    },
    mainTitleStyle() {
      // 存在副标题
      if (this.subTitle) {
        return {
          marginBottom: '.0533rem'
        }
      }
      // 只有主标题
      return {
        marginBottom: '.1067rem'
      }
    },
    // 标题图大小
    titleImageSize() {
      return {
        width: this.getTitleImgWidth() / 37.5 + 'rem',
        height: '.4267rem',
      }
    },
    // 大促大小
    bigSaleLogoSize() {
      return {
        width: this.getTitlebigSaleWidth() / 37.5 + 'rem',
        height: '.4267rem',
      }
    },
    subTitleStyle() {
      let fontSize = '0.2933rem'
      if (this.subTitleTextSize && !this.checkSizeUnit(this.subTitleTextSize)) {
        fontSize = (this.subTitleTextSize / 37.5) + 'rem'
      }
      // 存在副标题
      if (this.subTitle) {
        return {
          marginBottom: '.1067rem',
          fontSize,
        }
      }
      return {}
    },
    subTitleColor() {
      return hexToRGBA(this.titleColor, 0.8)
    },
    halfHeaderStye() {
      return {
        display: 'flex',
        justifyContent: 'space-between',
      }
    },
    cate() {
      switch (this.templateType) {
        case CompTemplateType.superDeals:
          {
            let categoryIds = ''
            if (this.useFrontCateId) {
              // 前台类目 id
              categoryIds = this.context?.curChannelInfo?.tspNodeIds || ''
            } else {
              categoryIds = this.context?.curChannelInfo?.realCateIds || ''
            }
            return { 
              markMap: this.markMap,
              hrefType: 'thriftyFind',
              categoryIds,
              nodeId: this.context?.curChannelInfo?.tspNodeIds || '',
              hrefTarget: this.context?.curChannelInfo?.tspNodeIds || '',
            }
          }
        case CompTemplateType.trend:
          return { 
            ...this.metaData,
            markMap: this.markMap,
            hrefType: 'trendsChannel',
            hrefTarget: this.context?.curChannelInfo?.realCateIds || ''
          }
        case CompTemplateType.brand:
          return { 
            ...this.metaData,
            markMap: this.markMap,
            hrefType: 'brandZone',
          }
        case CompTemplateType.shop:
          return { 
            markMap: this.markMap,
            hrefType: 'storeHome', 
            hrefTarget: 'noJump',
            categoryIds: this.context?.curChannelInfo?.realCateIds || '',
          }
        default:
          return {
            ...this.metaData,
            markMap: this.markMap,
          }
      }
      
    },
    adpProductsNum() {
      // 不带入展示商品,只带入点击商品
      if (this.homeAdpNum === 'no') {
        return 0
      } else if (parseInt(this.homeAdpNum)){
        return parseInt(this.homeAdpNum)
      } else {
        switch (this.componentSize) {
          case CompSize.full:
            return 10
          case CompSize.half:
            return 2
          default:
            return 1
        }
      }
    },
  },
  methods: {
    checkSizeUnit(size) {
      return /(px|em|rem|vh|vw|vmin|vmax|%)$/.test(size)
    },
    // 标题区埋点
    getTitleAnalysis({ id }) {
      return this.getAnalysisData(
        id,
        {
          item: {
            ...this.cate,
            item_loc: this.containerIndex + 1,
            component_area: this.componentSize, // 组件面积
            template: this.templateType, // 组件模板
          },
          index: this.index,
          useBffApi: true,
        },
        `${this.analysisCode}_title_code`
      )
    },
    getTitleImgWidth() {
      return 16 * Number(this.titleImage?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
    getTitlebigSaleWidth() {
      return 16 * Number(this.bigSaleLogo?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
    handleHeaderClick() {
      switch (this.templateType) {
        case CompTemplateType.superDeals:
          // 不贵跳转
          this.handleSuperDealsJump()
          break
        case CompTemplateType.trend:
          //趋势跳转
          this.handleTrendJump()
          break
        case CompTemplateType.brand:
        case CompTemplateType.category:
        case CompTemplateType.custom:
          this.handleOtherTemplateJump(this.productsList)
          break
        case CompTemplateType.shop:
          // 时尚店铺标题区不跳转,不做处理
          break
        default:
          break
      }
    },
    handleOtherTemplateJump(productList) {
      const params = {
        item: {
          ...this.metaData,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      
      const goodsIdList = productList?.slice(0, this.adpProductsNum)?.map((i) => i.goods_id)
      const goodsIds = goodsIdList.join(',') || ''
        
      if (this.metaData.hrefType !== 'webLink') {
        const adpStr = `adp=${goodsIds}`
        url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      }
      this.jumpLink({ url, hrefType: this.metaData.hrefType })
    },
    // 跳转到不贵频道
    handleSuperDealsJump() {
      const { hrefType, hrefTarget } = this.cate
      const params = {
        item: {
          ...this.metaData,
          hrefType,
          hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      // 列表需要的参数
      const listView =
        window?.localStorage.getItem('super_deals_product_list_view') || ''
      const productsList = this.productsList.slice(0, this.adpProductsNum)
      let flashIds, superIds
        // 组装数据
        superIds = [
          ...productsList
            .filter(i => i.type != 'flashSale')
            ?.map(i => i.goods_id)
        ].join(',')
        flashIds = productsList
          .filter(i => i.type == 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      let nodeId = '', realCateIds = ''
      switch (Number(this.metaData.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          nodeId = this.context?.curChannelInfo?.tspNodeIds
          realCateIds = this.context?.curChannelInfo?.realCateIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          nodeId = this.metaData.frontCateIds || this.context?.curChannelInfo?.tspNodeIds || ''
          realCateIds = this.metaData.categoryIds || this.context?.curChannelInfo?.realCateIds || ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      const adpStr = `adp=${superIds}&flash_adp=${flashIds}&cat_id=${realCateIds}&node_id=${nodeId}&pagefrom=page_home&list_view=${listView}`
      url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      url = `${url}&eventTimestamp=${new Date().getTime()}`
      if (this.isEarlyRequest0903) {
        url = `${url}&isEarlyRequest0903=1`
      }
      if (this.mallCodes) {
        url = `${url}&mall_code=${this.mallCodes}`
      }
      this.jumpLink({
        url,
        hrefType: 'super-deals'
      })
    },
    // 跳转到趋势频道
    handleTrendJump() {
      let query = {
        page_from: ['page_home', this.tabIndex !== '' ? this.tabIndex + 1 : '-', this.context?.curChannelInfo?.tabName || '-', 'FLEXIBLE_LAYOUT_FOR_SALEZONE' ].join('`')
      }
      // 增加真实分类id
      this.context?.curChannelInfo?.realCateIds && (query.catId = this.context?.curChannelInfo?.realCateIds || '')
      const productsList = this.productsList.slice(0, this.adpProductsNum)
      let arrList = productsList?.filter(item => item?.goods_id)?.map(item => `${item?.homeTrends?.contentCarrierId}_${item?.goods_id}`)
      if(arrList?.length) {
        query.contentCarrierId_adp = arrList.join(',')
      }
      const { hrefType, hrefTarget } = this.cate
      const params = {
        item: {
          ...this.metaData,
          hrefType,
          hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let queryString = Object.keys(query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&')
      url = url.indexOf('?') > -1 ? `${url}&${queryString}` : `${url}?${queryString}`
      if (!url || url.indexOf('javascript:;') > -1) return
      this.jumpLink({ url, hrefType: this.cate?.hrefType })
    },
  }
}
</script>

<style lang="less">
.multi-line__header {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  &_main {
    height: 0.4267rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;  
  }
  &_promotion.base-img {
    display: inline-block;
    margin-left: 0.1067rem; // 4px
    .base-img__inner {
      object-fit: contain;
    }
  }
  &_title-main {
    font-size: 0.3467rem;
    font-weight: 700;
    line-height: 0.4136rem;
    height: 0.4267rem;
    display: inline-block;
    max-width: 100%;
  }
  &_subTitle {
    font-size: 0.2933rem;
    font-weight: 400;
    line-height: 0.3501rem;
  }
  &_single {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-main {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .multi-line__header_subTitle {
      flex-grow: 1;
      text-align: right;
      padding-left: 4px;
      container-type: inline-size;
    }
    // 副标题被挤兑到一定程度时，隐藏。新样式规则，部分浏览器未支持
    @container (max-width: 25px) {
      .multi-line__header_subTitle p {
        display: none;
      }
    }
  }
}
</style>
